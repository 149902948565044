<template>
  <div>
      <Page/>
  </div>
</template>

<script>
import Page from './components/Page'
export default {
  components: {
    Page,
  },
  data() {
    return {
    }
  },
}
</script>
